<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-sliders-v"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Institution Programmes
                    </div>
                    <div class="text-sm text-500">
                      List of accredited institutions and programmes
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4 text-right">
              <Button
                label="Add New"
                icon="pi pi-plus"
                @click="
                  openPageDialog(
                    {
                      page: 'caps_institution_programmes/add',
                      url: `/caps_institution_programmes/add`,
                    },
                    { persistent: true, closeBtn: true }
                  )
                "
                class="w-fullbg-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col-12 md:col-3 comp-grid">
            <div class="card mb-3 nice-shadow-18">
              <div class="position-relative">
                <div class="">
                  <div class="flex align-items-center">
                    <div class="ml-2">
                      <div class="font-bold text-lg">
                        Filter by Institution Name
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-3" />
                <api-data-source
                  api-path="components_data/accredited_institution_programmescaps_institution_id_list"
                  v-slot="req"
                >
                  <div v-if="req.loading" class="p-3 text-center">
                    <ProgressSpinner style="width: 30px; height: 30px" />
                  </div>
                  <Menu v-else class="w-full" :model="req.response">
                    <template #item="{ item }">
                      <router-link
                        :to="`/caps_institution_programmes/caps_institution_id/${item.value}?label=${item.label}&tag=Institution Name`"
                      >
                        <Button
                          class="p-button-text text-left p-button-plain w-full flex justify-content-between align-items-center"
                        >
                          <span>{{ item.label }}</span>
                          <Badge
                            severity="info"
                            size="large"
                            v-if="item.num"
                            :value="item.num"
                          />
                        </Button>
                      </router-link>
                    </template>
                  </Menu>
                </api-data-source>
              </div>
            </div>
          </div>
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div
                v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente"
              >
                Search
                <Chip
                  class="font-medium px-2 py-1"
                  removable
                  @remove="clearSearch()"
                  >{{ searchText }}</Chip
                >
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb
                    :home="{
                      icon: 'pi pi-home',
                      to: '/caps_institution_programmes',
                    }"
                    :model="pageBreadCrumb"
                  />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records">
                      <DataTable
                        :lazy="true"
                        :loading="loading"
                        v-model:selection="selectedItems"
                        :value="records"
                        dataKey="caps_institution_programme_id"
                        @sort="onSort($event)"
                        class=""
                        :showGridlines="false"
                        :rowHover="true"
                        responsiveLayout="stack"
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle="width: 3em"
                        ></Column>
                        <Column
                          field="caps_institution_id"
                          header="Institution"
                        >
                          <template #body="{ data }">
                            <div>{{ data.caps_institutions_list_institution_name }}</div>
                          </template>
                        </Column>
                        <Column field="caps_programme_id" header="Programme">
                          <template #body="{ data }">
                            <div class="text-primary">{{ data.caps_programmes_list_programme_title }}</div>
                          </template>
                        </Column>
                        <Column
                          headerStyle="width: 3em"
                          headerClass="text-center"
                        >
                          <template #body="{ data }">
                            <Menubar
                              class="p-0"
                              ref="actionMenu"
                              :model="getActionMenuModel(data)"
                            />
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div
                        v-show="loading"
                        class="flex align-items-center justify-content-center text-gray-500"
                      >
                        <div>
                          <!-- <ProgressSpinner style="width: 30px; height: 30px" /> -->
                        </div>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <template v-if="!loading && !records.length">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        No record found
                      </div>
                    </template>
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div
                              class="flex justify-content-center flex-grow-0"
                            >
                              <div v-if="selectedItems.length" class="m-2">
                                <Button
                                  @click="deleteItem(selectedItems)"
                                  icon="pi pi-trash"
                                  class="p-button-danger"
                                  title="Delete Selected"
                                />
                              </div>
                            </div>
                            <div
                              v-if="paginate && totalPages > 1"
                              class="flex-grow-1 my-1"
                            >
                              <Paginator
                                @page="
                                  (event) => {
                                    pagination.page = event.page + 1;
                                  }
                                "
                                :rows="pagination.rowsPerPage"
                                :totalRecords="totalRecords"
                              >
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b
                                      >{{ recordsPosition }} of
                                      {{ totalRecords }}</b
                                    >
                                  </span>
                                </template>
                                <template #end> </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ListPageMixin } from "../../mixins/listpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "listCapsinstitutionprogrammesPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "caps_institution_programme_id",
    },
    pageName: {
      type: String,
      default: "caps_institution_programmes",
    },
    routeName: {
      type: String,
      default: "caps_institution_programmeslist",
    },
    apiPath: {
      type: String,
      default: "caps_institution_programmes/index",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: String,
      default: "20",
    },
    multiCheckbox: {
      type: Boolean,
      default: true,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
  },
  data() {
    return {};
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Institution Programmes";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["caps_institution_programmes/records"];
      },
      set: function (value) {
        this.$store.commit("caps_institution_programmes/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_institution_programmes/currentRecord"];
      },
      set: function (value) {
        this.$store.commit(
          "caps_institution_programmes/setCurrentRecord",
          value
        );
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("caps_institution_programmes", [
      "fetchRecords",
      "deleteRecord",
    ]),
    getActionMenuModel(data) {
      return [
        {
          label: "Remove",
          command: (event) => {
            this.deleteItem(data.caps_institution_programme_id);
          },
          icon: "pi pi-minus-circle",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
